<template>
  <section class="space-y-10">
    <article>
      <h3 class="subheader">
        Quick Links
      </h3>
      <br>
      <section class="relative z-0 rounded-md grid md:grid-cols-5 sm:grid-cols-1">
        <asom-button variant="primary" outline v-if="canUpdateCollectionSummary" rounded="none"
          @click="$router.push('collection-summary/certis-psm')" icon="swap" text="Certis PSM vs SeP">
        </asom-button>
        <asom-button variant="primary" outline v-if="canUpdateCollectionSummary" rounded="none"
          @click="$router.push('collection-summary/outgoing-sccf')" icon="swap" text="Outgoing SCCF vs MC CASHIN">
        </asom-button>
        <asom-button variant="primary" outline v-if="canUpdateCollectionSummary" rounded="none"
          @click="$router.push('collection-summary/incoming-sccf')" icon="swap" text="Incoming SCCF vs MC CASHOUT">
        </asom-button>
        <asom-button variant="primary" outline v-if="canUpdateCollectionSummary" rounded="none"
          @click="$router.push('collection-summary/cisco-report')" icon="swap" text="CISCO vs Report Cover">
        </asom-button>
      </section>
    </article>
    <article>
      <h3 class="subheader">
        Upload xlsx file
      </h3>
      <br>
      <asom-card title="Add Certis Report xlsx file">
        <asom-input-text accept=".xlsx" type="file" id="fileInput" ref="fileInput"
          @change="(e) => { onFileChange(e, 'formDataCertisReport') }" />
        <template #footer>
          <div class="flex justify-end flex-1">
            <asom-button :disabled="checkDisabledValidate('formDataCertisReport')"
              :text="formDataCertisReport.isValidating ? 'Validating...' : 'Validate'"
              @click="() => { validateFile('formDataCertisReport') }" />
          </div>
          <div class="flex justify-end">
            <asom-button :disabled="checkDisabledUpload('formDataCertisReport')"
              :text="formDataCertisReport.isUploading ? 'Uploading...' : 'Upload'"
              @click="() => { uploadFile('formDataCertisReport') }" />
          </div>
        </template>
      </asom-card>
      <br>
      <asom-card title="Add SeP xlsx file">
        <asom-input-text accept=".xlsx" type="file" id="fileInput" ref="fileInput"
          @change="(e) => { onFileChange(e, 'formDataSeP') }" />
        <template #footer>
          <div class="flex justify-end flex-1">
            <asom-button :disabled="checkDisabledValidate('formDataSeP')"
              :text="formDataSeP.isValidating ? 'Validating...' : 'Validate'"
              @click="() => { validateFile('formDataSeP') }" />
          </div>
          <div class="flex justify-end">
            <asom-button :disabled="checkDisabledUpload('formDataSeP')"
              :text="formDataSeP.isUploading ? 'Uploading...' : 'Upload'"
              @click="() => { uploadFile('formDataSeP') }" />
          </div>
        </template>
      </asom-card>
    </article>
    <article>
      <h3 class="subheader">
        Activity Logs
      </h3>
      <br>
      <activity-log-list :data="logList"></activity-log-list>
    </article>
  </section>
</template>
<script>
import get from 'lodash.get';
import { displayUtcDate } from '@/helpers/dateTimeHelpers'
import { formatCurrency } from '@/helpers/numbers'
import { validateCertisReport, validateCertisSep,validateCertisUpload, uploadCertisReport, getCertisReportActivityLog } from '../../../../services/cashManagement.service';
import ActivityLogList from "@/views/cash-management/_ActivityLogList.vue";
import { uploadFile } from '../../../../services/file.service';
import { ATTACHMENT_CATEGORIES } from "../../../../constants/APIEnums/attachmentEnums";
import { mapGetters } from 'vuex';

export default {
  components: {
    ActivityLogList,
  },
  data() {
    return {
      isLoading: false,
      error: null,
      sccfList: [],
      activityLogs: [],
      logList: [],
      formDataCertisReport: {
        file: null,
        validationResults: {
          errors: null,
          data: null,
          isValidationCompleted: false,
        },
        isValidating: false,
        isUploading: false,
      },
      formDataSeP: {
        file: null,
        validationResults: {
          errors: null,
          data: null,
          isValidationCompleted: false,
          isValidating: false,
          isUploading: false,
        },
        isValidating: false,
        isUploading: false,
      },
    }
  },
  mounted() {
    this.loadData();
  },
  computed: {
    ...mapGetters({
      canUpdateCollectionSummary: 'auth/canUpdateCollectionSummary',
    })
  },
  methods: {
    displayUtcDate,
    formatCurrency,
    checkDisabledUpload(type) {
      if (!['formDataCertisReport', 'formDataSeP'].includes(type)) {
        return true;
      }
      return Boolean(!this[type].file || !this[type].validationResults.data || this[type].isUploading || this[type].isValidating);
    },
    checkDisabledValidate(type) {
      if (!['formDataCertisReport', 'formDataSeP'].includes(type)) {
        return true;
      }
      return Boolean(!this[type].file || this[type].isValidating || this[type].isUploading)
    },
    async loadData() {
      this.getLogList();
    },
    async getLogList() {
      this.error = null;
      this.isLoading = true;
      const resp = await getCertisReportActivityLog({
        "lineId": this.$store.getters["auth/userLineOption"].value,
        "skip": 0,
        "take": 100,
        "search": ""
      });
      if (resp.success) {
        this.logList = resp.payload.list.map(item => ({
          ...item,
          message: item.attachmentName + " was uploaded",
          officerName: item.createdByName
        }));

      } else {
        this.error = resp.payload;
        this.logList.length = 0;
      }
      this.isLoading = false;
    },
    async validateFile(type) {
        if (this[type].file === null) return;
        this.error = null;
        this[type].validationResults.errors = [];
        this[type].validationResults.data = [];
        this[type].isValidationCompleted = false;
        this[type].isValidating = true;
      if(type === 'formDataCertisReport') {
        const result = await validateCertisReport([this[type].file]);
        if (result.success) {
          this[type].validationResults.errors = get(result, 'payload.errorList', []);
          this[type].validationResults.data = get(result, 'payload.data', []);
        } else {
          this.error = result.payload;
          window.alert(this.error.toString())
        }
  
        this[type].isValidationCompleted = true;
        this[type].isValidating = false;
      }else if(type === 'formDataSeP') {
        const result = await validateCertisSep([this[type].file]);
        if (result.success) {
          this[type].validationResults.errors = get(result, 'payload.errorList', []);
          this[type].validationResults.data = get(result, 'payload.data.sepReport', []);
        } else {
          this.error = result.payload;
          window.alert(this.error.toString())
        }
        this[type].isValidationCompleted = true;
        this[type].isValidating = false;
      }
    },
    async uploadFile(type) {
      if (!this[type].file) {
        return new Error('Please select a file to upload');
      }
      this[type].isUploading = true
      const uploadRes = await uploadFile([this[type].file], ATTACHMENT_CATEGORIES.CASH_MANAGEMENT);
      if (uploadRes.success) {
        let uploadReportRes = {};
        const attachmentId = get(uploadRes, 'payload.files.0.fileId');
        if (type === 'formDataCertisReport') {
          uploadReportRes = await uploadCertisReport({
            attachmentId,
            certisReportExcel: this[type].validationResults.data,
          })
        }else if (type === 'formDataSeP') {
          uploadReportRes = await validateCertisUpload({
            attachmentId,
            sepReportExcel: {
              sepReport: this[type].validationResults.data
            },
          })
        }
        this[type].validationResults.errors = [];
        this[type].validationResults.data = [];
        this[type].isValidationCompleted = false;
        this[type].isUploading = false;
        if (uploadReportRes.success) {
          console.log('成功')
        } else {
          console.log('失败')
        }
      }
    },
    onFileChange(event, type) {
      if (['formDataCertisReport', 'formDataSeP'].includes(type)) {
        this[type].file = event.target.files[0]
      }
    },
  }
}
</script>